import { ELessonBook, ELessonCategory, ELessonLevel, ELessonType, EPupilAvatar, ERecordingLanguage, ESchoolSubscription } from 'shared';
import { ESortBy } from '../../school-module/user/list';
const NL = {
  'avatar': {
    [EPupilAvatar.BELL]: 'Bel',
    [EPupilAvatar.BOOT]: 'Laars',
    [EPupilAvatar.BUNCH]: 'Tros',
    [EPupilAvatar.CHOIR]: 'Koor',
    [EPupilAvatar.CUP]: 'Kop',
    [EPupilAvatar.DEER]: 'Ree',
    [EPupilAvatar.DENT]: 'Deuk',
    [EPupilAvatar.DOOR]: 'Deur',
    [EPupilAvatar.FENCE]: 'Hek',
    [EPupilAvatar.FIN]: 'Vin',
    [EPupilAvatar.FIRE]: 'Vuur',
    [EPupilAvatar.FLAME]: 'Vlam',
    [EPupilAvatar.FLUTE]: 'Fluit',
    [EPupilAvatar.FOOT]: 'Voet',
    [EPupilAvatar.FOX]: 'Vos',
    [EPupilAvatar.FRUIT]: 'Fruit',
    [EPupilAvatar.GHOST]: 'Spook',
    [EPupilAvatar.GLASS]: 'Glas',
    [EPupilAvatar.GOAT]: 'Geit',
    [EPupilAvatar.HOP]: 'Hup',
    [EPupilAvatar.HOUR]: 'Uur',
    [EPupilAvatar.HOUSE]: 'Huis',
    [EPupilAvatar.HUT]: 'Hut',
    [EPupilAvatar.NEST]: 'Nest',
    [EPupilAvatar.PANTS]: 'Broek',
    [EPupilAvatar.PAW]: 'Poot',
    [EPupilAvatar.PEACOCK]: 'Pauw',
    [EPupilAvatar.PIG]: 'Big',
    [EPupilAvatar.ROAD]: 'Weg',
    [EPupilAvatar.SEA]: 'Zee',
    [EPupilAvatar.SEESAW]: 'Wip',
    [EPupilAvatar.STOOL]: 'Kruk',
    [EPupilAvatar.SUN]: 'Zon',
    [EPupilAvatar.SWEEP]: 'Veeg',
    [EPupilAvatar.UNCLE]: 'Oom',
    [EPupilAvatar.WALL]: 'Muur',
    [EPupilAvatar.WASP]: 'Wesp',
    [EPupilAvatar.WELL]: 'Put',
    [EPupilAvatar.WIG]: 'Pruik',
    [EPupilAvatar.WITCH]: 'Heks',
  },
  'lesson-level': {
    [ELessonLevel.LEVEL_3]: 'Groep 3',
    [ELessonLevel.LEVEL_4]: 'Groep 4',
    [ELessonLevel.LEVEL_5]: 'Groep 5',
    [ELessonLevel.LEVEL_6]: 'Groep 6',
    [ELessonLevel.LEVEL_7]: 'Groep 7',
    [ELessonLevel.LEVEL_8]: 'Groep 8',
  },
  'lesson-type': {
    [ELessonType.WORD_TOWER]: 'Woordtorentje les',
    [ELessonType.REPETITION]: 'Herhalingsles',
    [ELessonType.READING_COMPREHENSION]: 'Begrijpend lezen',
    [ELessonType.TEST]: 'Toets',
    [ELessonType.LIBRARY]: 'Bibliotheek',
    [ELessonType.WORD_ROWS]: 'Woordrijen'
  },
  'lesson-category': {
    [ELessonCategory.DECODING]: 'Technisch lezen ',
    [ELessonCategory.READING_COMPREHENSION]: 'Begrijpend lezen',
    [ELessonCategory.VOCABULARY]: 'Woordenschat',
    [ELessonCategory.STRONG_READER]: 'Verrijking',
    [ELessonCategory.BOOKSHELF]: 'Boekenplank',
  },
  'sort-by': {
    [ESortBy.NAME]: 'Naam',
    [ESortBy.TYPE]: 'Type',
    [ESortBy.CODE]: 'Code',
  },
  'lesson-book': {
    [ELessonBook.DECODING_ONE]: 'Technisch lezen 1',
    [ELessonBook.DECODING_TWO]: 'Technisch lezen 2',
    [ELessonBook.DECODING_THREE]: 'Technisch lezen 3',
    [ELessonBook.EXTRA_ONE]: 'Extra lesboek',
    [ELessonBook.READING_COMPREHENSION_ONE]: 'Begrijpend lezen 1',
    [ELessonBook.READING_COMPREHENSION_TWO]: 'Begrijpend lezen 2',
  },
  'book-selection': {
    'page-title': 'Selecteer het lesboek'
  },
  'category-selection': {
    'page-title': 'Selecteer de categorie'
  },
  'edit-lesson': {
    'errors': {
      'no-book-selected': 'Selecteer een lesboek'
    },
  },
  'lessons': {
    'search': 'Zoek een les'
  },
  'common': {
    'company': 'Kluitman Educatief',
    'add': 'Toevoegen',
    'save': 'Opslaan',
    'delete': 'Verwijderen',
    'keep': 'Behouden',
    'continue': 'Doorgaan',
    'contact-phone': '030 - 743 10 66',
    'contact-email': 'info@actieflerenlezen.nl',
    'lesson': 'Les',
    'sign-out': 'Uitloggen',
    'sign-in': 'Inloggen',
    'teacher-or-schooladmin': 'Leraar of schooladmin?',
    'user-menu': 'Gebruikers menu',
    'select-school': 'Selecteer school',
    'switch-module': 'Wisselen van omgeving',
    'question': 'Vraag',
    'answer': 'Antwoord',
    'text': 'Tekst',
    'yes': 'Ja',
    'no': 'Nee',
    'id': 'ID',
    'this-item': 'dit item',
    'confirm-delete': 'Weet je zeker dat je {{subject}} wilt verwijderen?',
    'language': {
      [ERecordingLanguage.NL_NL]: 'NL',
      [ERecordingLanguage.NL_BE]: 'BE',
    }
  },
  'school-home': {
    'title': 'Welkom in het Actief Leren Lezen dashboard!',
    'canvas': `
      <p>In dit dashboard log je als leerkracht in om groepen en leerlingen te beheren. In het menu links vind je de volgende items:</p>
      <h3>Voortgang</h3>
      <p>
        Onder "Voortgang" zie je een overzicht van jouw leerlingen en hoe ver ze zijn met de technisch lezen en woordenschat modules. In dit
        dashboard zie je de voortgang van de technisch lezen lessen en kan je op basis van die inzichten bepalen welke lessen de leerling
        nog een keer moet oefenen.
      </p>
      <h3>Gebruikers</h3>
      <p>
        Onder "Gebruikers" kun je groepen, leraren en leerlingen toevoegen, bekijken, wijzigen en verwijderen. Hier zie je welke avatar
        (illustratie) een leerling heeft en welk wachtwoord die gebruikt.
      </p>
      <h3>Feedback</h3>
      <p>
        De Actief Leren lezen app is op proef beschikbaar. We zijn erg benieuwd naar je ervaringen en suggesties. Heb je een opmerking of
        suggestie voor de Actief Leren Lezen app? Vul dan het formulier in onder feedback. We horen het graag.
      </p>
      <h2>De Actief Leren Lezen app voor leerlingen installeren</h2>
      <p>Leerlingen gebruiken de Actief Leren Lezen app op een tablet of een computer. De app is te vinden door in een webbrowser te surfen naar:</p>
      <p><a href="{{link}}" target="_blank">{{linkText}}</a></p>
      <p>
        Op een computer kunt u vervolgens deze link opslaan als een favoriet in de browser. Op een tablet kunt u dit het best doen door de app
        toe te voegen aan het startscherm. Instructies daarvoor vindt u hieronder.
      </p>
      <p>
        Het is het handigst als die beheerder of een leerkracht deze eerste stappen op alle apparaten eenmalig doorloopt. De leerlingen kunnen
        dan in het vervolg meteen hun klas kiezen en inloggen met de code die je onder gebruikers in het dashboard ziet. TIP: je kunt de codes
        voor de hele klas printen door daar ‘groepscodes printen’ te kiezen.
      </p>
      <h3>Actief Leren Lezen app toevoegen aan je startscherm op een tablet</h3>
      <p>Als de web app op een mobiel apparaat wordt gebruikt, dan is het handig om deze aan het startscherm toe te voegen. Dit doe je als volgt:<p>
      <ul>
        <li>Open de webbrowser op je tablet (bijvoorbeeld Chrome of Safari) en ga naar de link <a href="{{link}}" target="_blank">{{linkText}}</a></li>
        <li>
          Klik op de menuknop (vaak een vierkantje met een pijltje eruit, drie horizontale lijnen of een knop met drie puntjes) en selecteer “Zet op beginscherm”,
          “App installeren” of iets vergelijkbaars
        </li>
        <li>Er zal een pop-up verschijnen om de toevoeging te bevestigen. Klik op “Toevoegen” of “OK”.</li>
      </ul>
      <p>De Actief Leren Lezen web app zal nu toegevoegd worden aan het startscherm en zich gedragen als een gewone app.</p>
      <p><strong>Het kan zijn dat er wordt gevraagd om je schoolcode.<br/>Je schoolcode is: <code>{{friendlyId}}</code></strong></p>
      <h3>Ondersteunde apparaten, browsers en besturingssystemen</h3>
      <p>
        De Actief Leren Lezen web app werkt op zowel mobiele als desktop apparaten. Je kunt de web app gebruiken op tablets of iPads (met Safari) en op desktop apparaten zoals
        chromebooks, laptops en pc’s. De app is niet gemaakt voor mobiele telefoons.
      </p>
      <h4>Ondersteunde browsers</h4>
      <p>De Actief Leren Lezen web app ondersteunt de volgende browsers:</p>
      <ul>
        <li>Google Chrome (laatste versie)</li>
        <li>Mozilla Firefox (laatste versie)</li>
        <li>Microsoft Edge (laatste versie)</li>
        <li>Apple Safari (laatste versie)</li>
      </ul>
    `,
    'got-questions': 'Heb je vragen?',
  },
  'select-module': {
    'welcome': 'Actief Leren Lezen',
    'select-school': 'Selecteer de school om te bewerken',
    'continue-as-admin': 'Doorgaan als contentmanager',
    'login-to-continue': 'Log in om verder te gaan',
  },
  'hints': {
    'click-here-for-hints': 'Vraag Vlam om hulp',
    'close-hints': 'Stuur Vlam weg',
    'lesson-books': 'Hier kiest een leerling zijn/haar boek. Tik op een boek om verder te gaan',
    'choose-lesson': 'Hier kiest een leerling zijn/haar les. Tik op een les om verder te gaan',
    'choose-category': 'Hier kiest een leerling een categorie om aan te werken. Tik op een categorie om verder te gaan',
    'vocabulary-lessons': 'Hier kiest een leerling een woordplaat om aan te werken. Tik op een van de woordplaten om verder te gaan',
    'enter-password': 'Hier vult een leerling zijn/haar wachtwoord in. Deze leerling heeft als wachtwoord: ',
    'choose-who-you-are': 'Hier geeft een leerling aan wie hij/zij is. Tik op een leerling om verder te gaan',
    'choose-group': 'Hier kiest een leerling zijn/haar klas. Tik op een klas om verder te gaan'
  },
  'school': {
    'add-new-school': 'School toevoegen',
    'change-password-user': 'Wachtwoord aanpassen voor',
    'contact-email': 'Email contactpersoon',
    'contact-name': 'Naam contactpersoon',
    'edit-group': 'Aanpassen',
    'errors': {
      'technical-error': 'Er is een technische fout opgetreden',
    },
    'friendly-id': 'Friendly ID',
    'go-to': 'Bekijken',
    'language': 'Taal',
    'name': 'Naam',
    'no-subscription': 'Geen abonnement',
    'page-title': 'Scholen',
    'password': {
      'change-failed': 'Wachtwoord is niet aangepast',
      'change': 'Wachtwoord aanpassen',
      'changed': 'Wachtwoord is aangepast',
      'does-not-meet-requirements': 'Wachtwoord voldoet niet aan de eisen',
      'new': 'Nieuwe wachtwoord',
      'require-length': 'Minstens 8 tekens',
      'require-lowercase-letter': 'Bevat een kleine letter',
      'require-number': 'Bevat een nummer',
      'require-special-character': 'Bevat een speciaal teken ($&+,:;=?@#|\'<>.^*()%!-)',
      'require-uppercase-letter': 'Bevat een hoofdletter',
    },
    'print-group-codes': 'Groepscodes printen',
    'search-user': 'Zoek een gebruiker',
    'search': 'Zoek een school',
    'subscription-end': 'Verloop datum',
    'subscription-type': 'Type abonnement',
    'user': {
      'remove': 'Verwijderen',
    },
    'users': 'Gebruikers',
    'subscription': {
      'title': 'Abonnement',
      'expired': 'Verlopen: {{expired}}',
      'expiration': 'Abonnement verloopt op (YYYY-MM-DD):',
      [ESchoolSubscription.SUBSCRIPTION]: 'Abonnement',
      [ESchoolSubscription.TRIAL]: 'Proef licentie',
      'expiration-default': {
        [ESchoolSubscription.SUBSCRIPTION]: 'Standaard verloopt een abonnement niet',
        [ESchoolSubscription.TRIAL]: 'Standaard verloopt een proef licentie na drie maanden',
      },
      'expiration-custom': 'Aangepaste verlooptijd invoeren',
    },
    'navigation': {
      'feedback': 'Feedback'
    },
    'expired-subscription': {
      'almost-title': 'Het abonnement is bijna verlopen!',
      'almost-info': {
        'one': 'Over {{days}} dag verloopt de licentie. Als de licentie verlopen is kunnen de leerlingen niet meer inloggen om oefeningen te maken.',
        'other': 'Over {{days}} dagen verloopt de licentie. Als de licentie verlopen is kunnen de leerlingen niet meer inloggen om oefeningen te maken.',
      },
      'title': 'Het abonnement is verlopen!',
      'info': 'Doordat de licentie is verlopen kunnen de leerlingen niet meer inloggen om oefeningen te maken.',
      'contact-sales': 'Neem nu contact op met een leesspecialist',
    },
    'add-school': 'School toevoegen',
    'admin': {
      'user': {
        'title': 'School admin',
        'first-name': 'Voornaam',
        'last-name': 'Achternaam',
        'email': 'Email'
      }
    },
    'notifications': {
      'admin-failed': 'Door een onverwachte fout is de school aangemaakt zonder admin, probeer het opnieuw.',
    }
  },
  'pupil': {
    'expired-school-license': 'Deze school heeft geen geldige licentie',
    'too-many-password-attempts': 'Er zijn te veel pogingen gedaan om in te loggen, probeer het later opnieuw',
    'unknown-auth-error': 'Autorisatie mislukt door een onverwachte fout',
    'select-other-school': 'Andere school',
    'switch-to-login': 'Inloggen',
  },
  'pupil-progress': {
    'no-groups': 'Er kan geen voortgang getoond worden omdat je niet als leerkracht gekoppeld bent aan een groep',
    'page-title': 'Voortgang',
    'progress-of': 'Voortgang van',
    'lesson-type': 'Type les: ',
    'select-a-group': 'Selecteer een groep',
    'select-a-lesson-type': 'Selecteer een lestype',
    'edit-modal': {
      'title': 'Voortgang van {{name}} aanpassen',
      'mark-as-done': 'Markeer als klaar',
      'mark-as-retry': 'Markeer als opnieuw maken',
      'mark-as-locked': 'Markeer als nog niet gedaan',
      'mark-as-done-until': 'Markeer alle lessen tot hier als klaar',
      'mark-as-locked-from-here': 'Markeer alles vanaf hier als nog niet gedaan'
    }
  },
  'progress-detail': {
    'page-title': 'Voortgang van {{name}}',
  },
  'progress-summary': {
    'in-progress': 'Bezig',
    'finished': 'Afgerond',
    'details': 'Details',
    'correct': 'Goed',
    'wrong': 'Fout',
    'results': 'Resultaten',
  },
  'users': {
    'pupil': 'Leerling',
    'teacher': 'Leraar'
  },
  'errors': {
    'not-found': {
      'message': 'Hmmm... Die pagina kan ik niet vinden.',
      'button': 'Ga naar het beginscherm'
    }
  },
  'select-school': {
    'title': 'Welkom bij Actief Leren Lezen',
    'placeholder': 'code-van-school',
    'last-visited-school': 'Laatst bezochte school:',
    'empty-label': 'Geen school gevonden met die naam',
    'error': 'Er ging iets mis met het ophalen van de data',
    'help-text': 'Voer de code van jouw school hieronder in om je school te selecteren',
    'confirm': 'Selecteer {{schoolName}}'
  },
  'usage': {
    'title': 'Verbruik',
    'school': 'School',
    'contact-name': 'Contactpersoon',
    'contact-email': 'Contact email',
    'reference-date': 'Peildatum',
    'total-schools': 'Totaal scholen',
    'total-teachers': 'Totaal leerkrachten',
    'new-teachers': 'Nieuwe leerkrachten',
    'total-pupils': 'Totaal leerlingen',
    'new-pupils': 'Nieuwe leerlingen',
  },
  'dynamic-table': {
    'download-xlsx': 'Download als .xlsx',
    'default-filename': 'export',
  },
  'video': {
    'url': 'Vimeo URL',
    'delete': 'Video verwijderen',
  },
  'reading-comprehension': {
    'admin': {
      'audio-input-label': 'Audio opname van het verhaal',
      'audio-input-no-recording': 'Een opname is verplicht indien er een transcript moet zijn',
      'audio-input-speech-context-hint': 'Soms worden opnames niet goed geïnterpreteerd. Voer bij \'Ondersteunende teksten voor spraakherkenning\' per regel woorden en/of zinnen in die de spraakherkenning kunnen ondersteunen bij het interpreteren van de opname. Upload daarna de opname opnieuw.',
      'speech-context-title': 'Ondersteunende teksten voor spraakherkenning',
      'speech-context-placeholder': 'Dit is een voorbeeldzin\nvoorbeeld\nwoord',
      'transcript-label': 'Transcript van het verhaal',
      'text-label': 'Tekst van het verhaal',
      'delete-tekst': 'Verhaal verwijderen',
      'delete-question': 'Vraag verwijderen',
      'delete-answer': 'Antwoord verwijderen',
      'difficult-words': {
        'label': 'Moeilijk woord',
        'image': 'Afbeelding moeilijk woord',
        'delete': {
          'label': 'Verwijder moeilijk woord',
          'text': 'Weet je zeker dat je het woord wilt verwijderen? Dit kan niet ongedaan gemaakt worden.'
        },
        'already-exists': 'Het woord {{word}} bestaat al'
      },
      'questions': {
        'header': 'Vraag: {{question}}',
        'question': 'Vraag',
        'recording': 'Audio opname van de vraag',
      },
      'answers': {
        'correct-answer': 'Juiste antwoord',
        'incorrect-answer': 'Onjuist antwoord',
        'add-incorrect-answer': 'Onjuist antwoord toevoegen',
        'delete-incorrect-answer': 'Onjuist antwoord verwijderen'
      }
    },
    'correct-answer-title': 'Goed zo!',
    'correct-answer-is': 'Het goede antwoord is',
    'wrong-answer-title': 'Helaas!',
    'try-again': 'Probeer het opnieuw.'
  }
};

export default NL;
