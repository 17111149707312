import { useQuery } from 'react-query';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BOOK_AND_LESSON_TYPES, EInstructionLabel, ELessonBook, ELessonCategory, ELessonLevel, ELessonType, EPupilProgressStatus, IAvailability, IPupilLesson, LESSON_BOOKS, VideoExerciseData, WordTowerExerciseData } from 'shared';
import { Api } from '../common/api/api';
import { PupilLayout } from '../common/layouts/pupil.layout';
import { usePupil } from '../common/pupil-provider/pupil.context';
import { isEnumValue } from '../common/utils/input.helper';
import { InstructionLabel } from '../common/utils/instruction-label.component';
import { LessonCategory } from '../common/utils/lesson-category.component';
import { Status } from '../common/utils/status.component';
import { useHiddenObjectsGameData, useVideoData, useWordTowerData } from '../common/utils/use-lesson-document';
import { ELessonRouterPath } from '../router-path';
import iconPjf from './icon-pjf.png';
import iconLightBulb from './icon-light-bulb.png';
import iconChest from './icon-chest.png';
import iconGirlReading from './icon-girl-reading.png';
import { EIcon, Icon } from '../common/utils/icon.component';
import { getStorageUrl } from '../common/utils/storage.helper';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import paginateData from '../common/utils/paginate-data';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './list-lesson-items.scss';
import { getBookImage } from '../admin-module/lesson/lesson-book-images/lesson-book-images';
import { useEffect, useState } from 'react';

const lessonTypeFallbackImageMap: { [key: string]: string | undefined } = {
  [ELessonType.LIBRARY]: iconGirlReading,
  [ELessonType.READING_COMPREHENSION]: iconLightBulb,
  [ELessonType.REPETITION]: iconPjf,
  [ELessonType.TEST]: iconPjf,
  [ELessonType.WORD_ROWS]: iconPjf,
};

export interface ILessonBook { category: ELessonCategory, level: ELessonLevel, book: ELessonBook, image: string }

function Header({ titleImageSrc, title, instructionLabel, backPath }: { titleImageSrc: string, title: string | JSX.Element, instructionLabel: EInstructionLabel, backPath: string }): JSX.Element {
  const navigate = useNavigate();

  return <>
    <div className="list-lesson-items__header">
      <button className="list-lesson-items__header__back-button" onClick={() => navigate(backPath)}>
        <Icon>{EIcon.ARROW_LEFT}</Icon>
      </button>
      <img className="list-lesson-items__title-image" src={titleImageSrc} />
      <h1 className="list-lesson-items__title">{title}</h1>
    </div>
    <div className="list-lesson-items__instructions"><InstructionLabel instructionKey={instructionLabel} /></div>
  </>;
}

export function ListDecodingLessonsTile({ pupilLesson, renderPlain }: { pupilLesson: IPupilLesson, renderPlain?: boolean }): JSX.Element {
  const { lesson, state } = pupilLesson;
  const exerciseData = useWordTowerData(lesson);
  const isBookOnlyLesson = state === EPupilProgressStatus.DISABLED;
  const isBookAndLesson = state !== EPupilProgressStatus.DISABLED && lesson?.type && BOOK_AND_LESSON_TYPES.includes(lesson.type);

  const getLessonImage = (exerciseData: WordTowerExerciseData | null): string => {
    const fallback = lessonTypeFallbackImageMap[lesson.type || ''] || iconPjf;
    const url = exerciseData?.image && getStorageUrl(exerciseData.image.smallFilename);
    return url || fallback;
  };

  const icons: { [status: string]: EIcon | undefined } = {
    [EPupilProgressStatus.RETRY]: EIcon.REFRESH,
    [EPupilProgressStatus.STARTED]: EIcon.PROGRESS,
    [EPupilProgressStatus.FINISHED]: EIcon.CHECK_CIRCLE,
    [EPupilProgressStatus.LOCKED]: EIcon.LOCK,
  };

  const renderTooltip = () => {
    if (!isBookOnlyLesson || renderPlain) {
      return null;
    }
    return <div className="decoding-lesson-list__tooltip">
      <Icon className="decoding-lesson-list__tooltip-icon">{EIcon.OPEN_BOOK}</Icon> <InstructionLabel instructionKey={EInstructionLabel.PAK_JE_BOEK} />
      <div className="decoding-lesson-list__tooltip-arrow" />
    </div>;
  };

  const renderHeader = () => {
    if (state === EPupilProgressStatus.LOCKED) {
      return null;
    }
    const icon = (isBookOnlyLesson || isBookAndLesson) ? EIcon.OPEN_BOOK : icons[state];
    return <div className="decoding-lesson-list__tile-header">
      <span className="decoding-lesson-list__tile-title">Les {lesson.number}</span>
      {icon && <Icon className="decoding-lesson-list__tile-status-icon">{icon}</Icon>}
    </div>;
  };

  const renderImage = () => {
    if (state === EPupilProgressStatus.NEW) {
      return <Icon className="decoding-lesson-list__title-image">{EIcon.NEW}</Icon>;
    } else if (state === EPupilProgressStatus.LOCKED) {
      return <Icon className="decoding-lesson-list__title-image">{EIcon.LOCK}</Icon>;
    }
    return <img className="decoding-lesson-list__title-image" src={getLessonImage(exerciseData)} alt="" />;
  };

  const renderContent = ({ tabbable }: { tabbable: boolean }) => (
    <div className="decoding-lesson-list__tile-content" tabIndex={tabbable ? 0 : undefined}>
      {renderTooltip()}
      {renderHeader()}
      {renderImage()}
    </div>
  );

  const renderButton = () => {
    if (renderPlain) {
      return renderContent({ tabbable: false });
    }

    if (isBookOnlyLesson) {
      return renderContent({ tabbable: true });
    }

    if (state === EPupilProgressStatus.LOCKED) {
      return renderContent({ tabbable: false });
    }

    return <Link to={ELessonRouterPath.SHOW_LESSON.fullPath(lesson.id)} className="decoding-lesson-list__tile-link">{renderContent({ tabbable: false })}</Link>;
  };

  const className = `decoding-lesson-list__tile decoding-lesson-list__tile--type-${lesson.type || 'none'} decoding-lesson-list__tile--state-${state}`;
  if (renderPlain) {
    return <div className={className}>
      {renderButton()}
    </div>;
  } else {
    return <li className={className}>
      {renderButton()}
    </li>;
  }
}

function ListDecodingLessons({ selectedBook, level }: { selectedBook: ELessonBook | null, level: ELessonLevel }): JSX.Element {
  const { pupil } = usePupil();
  const { data: pupilLessons, error, isLoading } = useQuery(['decoding-lessons', pupil, selectedBook],
    () => Api.listPupilLessons({ category: ELessonCategory.DECODING, book: selectedBook ?? undefined }));
  const LESSONS_PER_SLIDE = 18;
  const slides: IPupilLesson[][] | undefined = pupilLessons && paginateData(pupilLessons, LESSONS_PER_SLIDE);

  return <PupilLayout homeButton={true}>
    <Header
      titleImageSrc={iconPjf}
      title={<LessonCategory value={ELessonCategory.DECODING} upper={true} level={level} />}
      instructionLabel={EInstructionLabel.MET_WELKE_LES_GA_JIJ_VERDER}
      backPath={ELessonRouterPath.LIST_LESSONS.fullPath(ELessonCategory.DECODING)}
    />
    <Status loading={isLoading} error={error} />
    <Swiper
      modules={[Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {slides?.map((slide, idx) => (
        <SwiperSlide key={idx}>
          {slide.map(pupilLesson => <ListDecodingLessonsTile key={(pupilLesson).lesson.id} pupilLesson={pupilLesson} />)}
        </SwiperSlide>
      ))}
    </Swiper>
  </PupilLayout>;
}

function ListReadingComprehensionLessons({ selectedBook }: { selectedBook: ELessonBook | null }): JSX.Element {
  const { pupil } = usePupil();
  const { data: pupilLessons, error, isLoading } = useQuery(['reading-comprehension-lessons', pupil, selectedBook],
    () => Api.listPupilLessons({ category: ELessonCategory.READING_COMPREHENSION, book: selectedBook ?? undefined }));
  const LESSONS_PER_SLIDE = 18;
  const slides: IPupilLesson[][] | undefined = pupilLessons && paginateData(pupilLessons, LESSONS_PER_SLIDE);

  return <PupilLayout homeButton={true}>
    <Header
      titleImageSrc={iconPjf}
      title={<LessonCategory value={ELessonCategory.READING_COMPREHENSION} upper={true} />}
      instructionLabel={EInstructionLabel.MET_WELKE_LES_GA_JIJ_VERDER}
      backPath={ELessonRouterPath.LIST_LESSONS.fullPath(ELessonCategory.READING_COMPREHENSION)}
    />
    <Status loading={isLoading} error={error} />
    <Swiper
      modules={[Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {slides?.map((slide, idx) => (
        <SwiperSlide key={idx}>
          {slide.map(pupilLesson => <ListDecodingLessonsTile key={(pupilLesson).lesson.id} pupilLesson={pupilLesson} />)}
        </SwiperSlide>
      ))}
    </Swiper>
  </PupilLayout>;
}

function ListVocabularyLessonsTile({ pupilLesson }: { pupilLesson: IPupilLesson }): JSX.Element {
  const { lesson } = pupilLesson;
  const exerciseData = useHiddenObjectsGameData(lesson);

  return <div key={lesson.id} className="vocabulary-lesson-list__tile">
    <Link to={ELessonRouterPath.SHOW_LESSON.fullPath(lesson.id)} className="vocabulary-lesson-list__tile-link">
      <div className="vocabulary-lesson-list__tile-content">
        {exerciseData?.image && <img className="vocabulary-lesson-list__title-image" src={getStorageUrl(exerciseData.image.smallFilename)} alt="" />}
        <span className="vocabulary-lesson-list__title-title">{lesson.number}. {lesson.name}</span>
      </div>
    </Link>
  </div>;
}

function ListVocabularyLessons(): JSX.Element {
  const { pupil } = usePupil();
  const { data: pupilLessons, error, isLoading } = useQuery(['vocabulary-lessons', pupil],
    () => Api.listPupilLessons({ category: ELessonCategory.VOCABULARY }));
  const LESSONS_PER_SLIDE = 6;
  const slides: IPupilLesson[][] | undefined = pupilLessons && paginateData(pupilLessons, LESSONS_PER_SLIDE);

  return <PupilLayout homeButton={true}>
    <Header
      titleImageSrc={iconChest}
      title={<LessonCategory value={ELessonCategory.VOCABULARY} upper={true} />}
      instructionLabel={EInstructionLabel.KIES_EEN_WOORDENSCHAT}
      backPath={ELessonRouterPath.HOME.fullPath}
    />
    <Status loading={isLoading} error={error} />
    <Swiper
      modules={[Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {slides?.map((slide, idx) => (
        <SwiperSlide key={idx}>
          {slide.map(pupilLesson => <ListVocabularyLessonsTile key={(pupilLesson).lesson.id} pupilLesson={pupilLesson} />)}
        </SwiperSlide>
      ))}
    </Swiper>
  </PupilLayout>;
}

const ListBookTile = ({ image, book }: { image: string, book: ELessonBook }) => {
  const [, setSearchParams] = useSearchParams();
  return (
    <li key={book}>
      <button className="book-list__tile-button" onClick={() => setSearchParams({ 'book': book })}>
        <img className="book-list__tile-image" src={image} alt={book} />
      </button>
    </li>
  );
};

const ListStrongReaderLessonsTile = ({ pupilLesson }: { pupilLesson: IPupilLesson }): JSX.Element => {
  const { lesson, state } = pupilLesson;
  const exerciseData = useVideoData(lesson);
  const getLessonImage = (exerciseData: VideoExerciseData | null): string => {
    const fallback = lessonTypeFallbackImageMap[lesson.type || ''] || iconPjf;
    const url = exerciseData?.image && getStorageUrl(exerciseData.image.smallFilename);
    return url || fallback;
  };

  const icons: { [status: string]: EIcon | undefined } = {
    [EPupilProgressStatus.RETRY]: EIcon.REFRESH,
    [EPupilProgressStatus.STARTED]: EIcon.PROGRESS,
    [EPupilProgressStatus.FINISHED]: EIcon.CHECK_CIRCLE,
    [EPupilProgressStatus.LOCKED]: EIcon.LOCK,
  };

  const renderImage = () => {
    if (state === EPupilProgressStatus.NEW) {
      return <Icon className="strong-reader-lesson-list__title-image">{EIcon.NEW}</Icon>;
    } else if (state === EPupilProgressStatus.LOCKED) {
      return <Icon className="strong-reader-lesson-list__title-image">{EIcon.LOCK}</Icon>;
    }
    return <img className="strong-reader-lesson-list__title-image" src={getLessonImage(exerciseData)} alt="" />;
  };
  const renderHeader = () => {
    if (state === EPupilProgressStatus.LOCKED) {
      return null;
    }
    const icon = icons[state];
    return <div className="strong-reader-lesson-list__tile-header">
      <span className="strong-reader-lesson-list__tile-title">Les {lesson.number}</span>
      {icon && <Icon className="strong-reader-lesson-list__tile-status-icon">{icon}</Icon>}
    </div>;
  };
  return (
    <div key={lesson.id} className={`strong-reader-lesson-list__tile strong-reader-lesson-list__tile--state-${state}`}>
      <Link to={ELessonRouterPath.SHOW_LESSON.fullPath(lesson.id)} className="strong-reader-lesson-list__tile-link">
        <div className="strong-reader-lesson-list__tile-content">
          {renderHeader()}
          {renderImage()}
        </div>
      </Link>
    </div>
  );
};

const ListBooks = ({ books }: { books: typeof LESSON_BOOKS[0] }): JSX.Element => {
  const [availabilities, setAvailabilities] = useState<IAvailability[]>([]);
  const [availableBooks, setAvailableBooks] = useState<ELessonBook[]>([]);
  const { data, status } = useQuery(['books', books], () => Api.findAllByLevelCategory({ level: books.level, category: books.category }));

  useEffect(() => {
    if (status === 'success') {
      setAvailabilities(data);
    }
  }, [data, status]);

  useEffect(() => {
    setAvailableBooks(books.books.filter(book => availabilities.find(availability => availability.book === book)));
  }, [availabilities]);

  if (!availableBooks) {
    return <Status error="Geen boeken gevonden" />;
  }
  return <PupilLayout homeButton={true}>
    <Header
      titleImageSrc={iconPjf}
      title={<LessonCategory value={books.category} upper={true} level={books.level} />}
      instructionLabel={EInstructionLabel.BIJ_WELK_BOEK_BEN_JIJ}
      backPath={ELessonRouterPath.HOME.fullPath}
    />
    <ol className="book-list">
      {availableBooks.map((book) => <ListBookTile key={book} book={book} image={getBookImage(books.level, book)} />)}
    </ol>
  </PupilLayout>;
};

const ListStrongReaderLessons = (): JSX.Element => {
  const { pupil } = usePupil();
  const { data: pupilLessons, error, isLoading } = useQuery(['strong-reader-lessons', pupil],
    () => Api.listPupilLessons({ category: ELessonCategory.STRONG_READER }));
  const LESSONS_PER_SLIDE = 6;
  const slides: IPupilLesson[][] | undefined = pupilLessons && paginateData(pupilLessons, LESSONS_PER_SLIDE);

  return (
    <PupilLayout homeButton={true}>
      <Header
        titleImageSrc={iconPjf}
        title={<LessonCategory value={ELessonCategory.STRONG_READER} upper={true} />}
        instructionLabel={EInstructionLabel.MET_WELKE_LES_GA_JIJ_VERDER}
        backPath={ELessonRouterPath.HOME.fullPath}
      />
      <Status loading={isLoading} error={error} />
      <Swiper
        modules={[Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        {slides?.map((slide, idx) => (
          <SwiperSlide key={idx}>
            {slide.map(pupilLesson => <ListStrongReaderLessonsTile key={(pupilLesson).lesson.id} pupilLesson={pupilLesson} />)}
          </SwiperSlide>
        ))}
      </Swiper>
    </PupilLayout>
  );
};

export function ListLessonItems(): JSX.Element {
  const { pupil } = usePupil();
  const { data: pupilGroup, error, isLoading } = useQuery(['pupil-group', pupil], () => {
    if (!pupil) {
      return Promise.resolve(undefined);
    }
    return Api.getPupilGroup(pupil.id);
  });
  const [searchParams] = useSearchParams();
  const params = useParams();
  const lessonCategoryParam = params.lessonCategory || '';
  const lessonBookParam = searchParams.get('book') || '';
  const lessonCategory = isEnumValue(ELessonCategory, lessonCategoryParam) ? lessonCategoryParam as ELessonCategory : null;
  const lessonBooks = (pupilGroup && LESSON_BOOKS.find(item => item.category === lessonCategory && item.level === pupilGroup.level)) || null;
  const lessonBook = isEnumValue(ELessonBook, lessonBookParam) ? lessonBookParam as ELessonBook : null;

  if (isLoading) {
    return <Status loading={true} />;
  } else if (error) {
    return <Status error={error} />;
  }

  if (!lessonBook && lessonBooks?.books) {
    return <ListBooks books={lessonBooks} />;
  }

  if (lessonCategory === ELessonCategory.DECODING) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return <ListDecodingLessons selectedBook={lessonBook} level={pupilGroup!.level} />;
  }

  if (lessonCategory === ELessonCategory.READING_COMPREHENSION) {
    return <ListReadingComprehensionLessons selectedBook={lessonBook} />;
  }

  if (lessonCategory === ELessonCategory.VOCABULARY) {
    return <ListVocabularyLessons />;
  }

  if (lessonCategory === ELessonCategory.STRONG_READER) {
    return <ListStrongReaderLessons />;
  }

  return <Status error={`Onbekende categorie (${lessonCategory || 'null'})`} />;
}
