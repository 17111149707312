import { useTranslation } from 'react-i18next';
import { EExerciseType, ReadingComprehensionTextExercise, Recording } from 'shared';
import { DangerButton } from '../../../../common/utils/danger-button.component';
import { EIcon } from '../../../../common/utils/icon.component';
import { IExerciseEditorProps } from './exercise-editor.component';
import { Box, BoxDivider } from '../../../../common/utils/box.component';
import { getExerciseTypeTitle } from '../../../../common/utils/exercise-type.component';
import { ReadingComprehensionTextExerciseData } from 'shared/src/lesson-document/exercises/exercise-reading-comprehension-text';
import { ReadingComprehensionDifficultWordEditor } from './reading-comprehension/difficult-word-editor';
import { ReadingComprehensionRichTextEditor } from './reading-comprehension/rich-text-editor';
import './reading-comprehension-transcript-editor.component.scss';
import ReadingComprehensionQuestionEditor from './reading-comprehension/question-editor';
import Button from 'react-bootstrap/esm/Button';

const ReadingComprehensionTextEditor = ({ value, onChange, onDelete }: IExerciseEditorProps<ReadingComprehensionTextExercise>): JSX.Element => {
  const data: ReadingComprehensionTextExerciseData = value.data ?? {};

  const text = data.text ?? '';
  const recording = data.recording ?? null;
  const difficultWords = data.words ?? [];
  const questions = (data.questions ?? []);

  const { t } = useTranslation();

  const setText = (text: typeof data.text): void => {
    onChange({ ...value, data: { ...value.data, text } });
  };

  const setRecording = (recording: typeof data.recording) => {
    onChange({ ...value, data: { ...value.data, recording: recording || undefined } });
  };

  const setDifficultWords = (difficultWords: typeof data.words) => {
    onChange({ ...value, data: { ...data, words: difficultWords } });
  };

  const handleDelete = (): void => {
    onDelete({ ...value });
  };

  const handleRecordingChanged = (recording: Recording | null) => {
    setRecording(recording ?? undefined);
  };

  const setQuestions = (questions: typeof data.questions) => {
    onChange({ ...value, data: { ...data, questions } });
  };

  const handleDeleteQuestion = (index: number) => {
    const copy = [...questions];
    copy.splice(index, 1);
    setQuestions(copy);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions, {
        question: {
          text: '',
          recording: null,
        },
        answers: {
          correct: '',
          incorrect: ['']
        }
      }
    ]);
  };

  const handleQuestionChange = (index: number, value: string) => {
    const copy = [...questions];
    copy[index].question.text = value;
    setQuestions(copy);
  };

  const handleQuestionRecordingChanged = (recording: Recording | null, index: number) => {
    const copy = [...questions];
    copy[index].question.recording = recording;
    setQuestions(copy);
  };

  const handleCorrectAnswerChanged = (value: string, index: number) => {
    const copy = [...questions];
    copy[index].answers.correct = value;
    setQuestions(copy);
  };

  const handleIncorrectAnswerChanged = (value: string, questionIndex: number, answerIndex: number) => {
    const copy = [...questions];
    copy[questionIndex].answers.incorrect[answerIndex] = value;
    setQuestions(copy);
  };

  const handleAddIncorrectAnswer = (questionIndex: number) => {
    const copy = [...questions];
    copy[questionIndex].answers.incorrect.push('');
    setQuestions(copy);
  };

  const handleDeleteIncorrectAnswer = (questionIndex: number, answerIndex: number) => {
    const copy = [...questions];
    copy[questionIndex].answers.incorrect.splice(answerIndex, 1);
    setQuestions(copy);
  };

  const renderBoxHeader = (handleDelete: (index?: number) => void, subject: string): JSX.Element => <>
    <DangerButton
      icon={EIcon.MINUS_CIRCLE}
      onClick={handleDelete}
      modalText={t('common:confirm-delete', { subject: t('common:this-item') })}
      modalCancelLabel={t('common:keep')}
      modalConfirmLabel={t('common:delete')}
    >{subject}</DangerButton>
  </>;

  return (
    <Box title={getExerciseTypeTitle({ value: EExerciseType.READING_COMPREHENSION_TEXT, upper: true })} headerContent={renderBoxHeader(handleDelete, t('reading-comprehension:admin.delete-tekst'))}>
      <ReadingComprehensionRichTextEditor
        text={text}
        onChangeText={setText}
        difficultWords={difficultWords}
        recording={recording}
        onRecordingChanged={handleRecordingChanged}
      />
      <ReadingComprehensionDifficultWordEditor text={text} words={difficultWords} onWordsChange={setDifficultWords} />
      {questions && questions.map((question, index) => <div key={index}>
        <BoxDivider
          title={t('reading-comprehension:admin.questions.header', { question: question.question?.text })}
          content={<DangerButton onClick={() => handleDeleteQuestion(index)}>{t('reading-comprehension:admin.delete-question')}</DangerButton>}
        />
        <ReadingComprehensionQuestionEditor key={index} handleQuestionChange={handleQuestionChange} question={question} index={index} handleQuestionRecordingChanged={handleQuestionRecordingChanged}
          handleCorrectAnswerChanged={handleCorrectAnswerChanged}
          handleAddIncorrectAnswer={handleAddIncorrectAnswer}
          handleDeleteIncorrectAnswer={handleDeleteIncorrectAnswer}
          handleIncorrectAnswerChanged={handleIncorrectAnswerChanged} />
      </div>)}
      <BoxDivider />
      <Button onClick={handleAddQuestion}>Voeg een vraag toe</Button>
    </Box>
  );
};

export default ReadingComprehensionTextEditor;
