import { Group, Image, Lesson, Pupil, School, User } from './types';
import { Request } from './request';
import { ELessonBook, ELessonCategory, ELessonLevel, EPupilProgressStatus, IAvailability, IGroup, IInstructionLabel, IPupil, IPupilLesson, IPupilProgress, IPupilWithLanguage, ITeacher, IUsage, IUsageRun, IUserWithEmail, RecordingItem, TInstructionLabelMap } from 'shared';
import { IUserDetails } from 'shared/src/user.entity';

function buildQueryString(params: { [key: string]: number|string|boolean }): string {
  const convertValue = (value: number|string|boolean): string => {
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    }
    return `${value}`;
  };
  const queryString = Object.entries(params).map(([k, v]) => `${k}=${convertValue(v)}`).join('&');
  if (queryString.length === 0) {
    return '';
  }
  return `?${queryString}`;
}

export abstract class Api {
  // User
  static userMe = (): Promise<IUserDetails> => Request.get<IUserDetails>('/user/me');
  static listUsers = (): Promise<User[]> => Request.get<User[]>('/user');
  static createUser = (user: { firstName: string, lastName: string, email: string }): Promise<User> => Request.post<User>('/user/', user);
  static getUser = (id: number): Promise<User> => Request.get<User>(`/user/${id}`);
  static changePasswordUser = (userId: number, newPassword: string): Promise<void> => Request.put<void>(`/user/${userId}/changepassword`, { newPassword: newPassword });

  // Availability
  static findAvailability = (params: Pick<IAvailability, 'level' | 'category'> & Partial<Pick<IAvailability, 'book'>>): Promise<IAvailability> => {
    const queryParams: Record<string, any> = {
      level: params.level,
      category: params.category,
    };

    if (params.book) {
      queryParams.book = params.book;
    }

    return Request.get<IAvailability>(`/availability${buildQueryString(queryParams)}`);
  };

  static findAllByLevelCategory = (params: Pick<IAvailability, 'level' | 'category'>): Promise<IAvailability[]> => Request.get<IAvailability[]>(`/availability/lessons${buildQueryString(params)}`, { optionalUserAuth: true });
  static updateAvailability = (availability: Omit<IAvailability, 'id' | 'createdAt' | 'updatedAt'>): Promise<IAvailability> => Request.put<IAvailability>('/availability', availability);
  static findAvailableCategoriesByLevel = (level: ELessonLevel): Promise<ELessonCategory[]> => Request.get<ELessonCategory[]>(`/availability/books${buildQueryString({ level })}`, { optionalUserAuth: true });
  static getAvailableLevels = (): Promise<ELessonLevel[]> => Request.get<ELessonLevel[]>('/availability/levels');

  // School
  static listSchools = (): Promise<School[]> => Request.get<School[]>('/school');
  static createSchool = (school: Omit<School, 'id' | 'invalidSubscription'>): Promise<School> => Request.post<School>('/school', school);
  static updateSchool = (id: number, school: Omit<School, 'id' | 'invalidSubscription'>): Promise<School> => Request.put<School>(`/school/${id}`, school);
  static getSchool = (id: number|string): Promise<School> => Request.get<School>(`/school/${id}`, { optionalUserAuth: true });
  // School admins
  static listSchoolAdmins = (schoolId: number): Promise<IUserWithEmail[]> => Request.get<IUserWithEmail[]>(`/school/${schoolId}/admin`);
  static addSchoolAdmin = (schoolId: number, data: { firstName: string, lastName: string, email: string }): Promise<User> => Request.post<User>(`/school/${schoolId}/admin`, data);
  static removeSchoolAdmin = (schoolId: number, userId: number): Promise<void> => Request.delete<void>(`/school/${schoolId}/admin/${userId}`);
  // School groups
  static listSchoolGroups = (schoolId: number): Promise<Group[]> => Request.get<Group[]>(`/school/${schoolId}/group`, { optionalUserAuth: true });
  static listSchoolGroupPupils = (schoolId: number, groupId: number): Promise<IPupil[]> => Request.get<IPupil[]>(`/school/${schoolId}/group/${groupId}/pupil`, { optionalUserAuth: true });
  static createSchoolGroup = (schoolId: number, newGroup: Omit<Group, 'id'|'schoolId'>): Promise<Group> => Request.post<Group>(`/school/${schoolId}/group`, newGroup);
  static updateSchoolGroup = (schoolId: number, groupId: number, updatedGroup: Omit<Group, 'id'|'schoolId'>): Promise<Group> => Request.put<Group>(`/school/${schoolId}/group/${groupId}`, updatedGroup);
  static deleteSchoolGroup = (schoolId: number, groupId: number): Promise<void> => Request.delete<void>(`/school/${schoolId}/group/${groupId}`);
  // School pupil
  static getSchoolPupil = (schoolId: number, pupilId: number): Promise<Pupil> => Request.get<Pupil>(`/school/${schoolId}/pupil/${pupilId}`);
  static listSchoolPupils = (schoolId: number): Promise<Pupil[]> => Request.get<Pupil[]>(`/school/${schoolId}/pupil`);
  static createSchoolPupil = (schoolId: number, newPupil: Omit<Pupil, 'id'>): Promise<Pupil> => Request.post<Pupil>(`/school/${schoolId}/pupil`, newPupil);
  static updateSchoolPupil = (schoolId: number, pupilId: number, updatedPupil: Omit<Pupil, 'id'>): Promise<Pupil> => Request.put<Pupil>(`/school/${schoolId}/pupil/${pupilId}`, updatedPupil);
  static deleteSchoolPupil = (schoolId: number, pupilId: number): Promise<void> => Request.delete<void>(`/school/${schoolId}/pupil/${pupilId}`);
  // School teachers
  static listSchoolTeachers = (schoolId: number): Promise<ITeacher[]> => Request.get<ITeacher[]>(`/school/${schoolId}/teacher`);
  static createSchoolTeacher = (schoolId: number, data: { firstName: string, lastName: string, email: string, groupIds: number[] }): Promise<ITeacher> => Request.post<ITeacher>(`/school/${schoolId}/teacher`, { ...data });
  static updateSchoolTeacher = (schoolId: number, userId: number, data: { groupIds: number[], avatar?: string }): Promise<ITeacher> => Request.put<ITeacher>(`/school/${schoolId}/teacher/${userId}`, data);
  static deleteSchoolTeacher = (schoolId: number, userId: number): Promise<void> => Request.delete<void>(`/school/${schoolId}/teacher/${userId}`);

  // Pupil
  static pupilMe = (): Promise<IPupilWithLanguage> => Request.get<IPupilWithLanguage>('/pupil/me', { optionalUserAuth: true });
  static authorizePupil = (pupilId: number, password: string): Promise<{ accessToken: string }> => Request.post<{ accessToken: string }>(`/pupil/${pupilId}/auth`, { password }, { optionalUserAuth: true });
  static listPupilLessons = (params: { category: ELessonCategory, book?: ELessonBook }): Promise<IPupilLesson[]> => Request.get<IPupilLesson[]>(`/pupil/lesson${buildQueryString(params)}`, { optionalUserAuth: true });
  static getPupilLesson = (lessonId: number): Promise<IPupilLesson> => Request.get<IPupilLesson>(`/pupil/lesson/${lessonId}`, { optionalUserAuth: true });
  static getPupilGroup = (pupilId: number): Promise<IGroup> => Request.get<IGroup>(`/pupil/${pupilId}/group`, { optionalUserAuth: true });
  // Pupil progress
  static listPupilProgress = (params: { category?: ELessonCategory, level?: ELessonLevel, groupId?: number } = {}): Promise<IPupilProgress[]> => Request.get<IPupilProgress[]>(`/pupil/progress${buildQueryString(params)}`, { optionalUserAuth: true });
  static listSinglePupilProgress = (pupilId: number, params: { category?: ELessonCategory, level?: ELessonLevel } = {}): Promise<IPupilProgress[]> => Request.get<IPupilProgress[]>(`/pupil/progress/${pupilId}${buildQueryString(params)}`);
  static listPupilLessonsAsTeacher = (pupilId: number, category: ELessonCategory): Promise<IPupilLesson[]> => Request.get<IPupilLesson[]>(`/pupil/${pupilId}/lessons?category=${category}`);
  static updateOwnPupilProgress = (body: { lessonId: number, status: EPupilProgressStatus, result: string }): Promise<IPupilProgress> => Request.put<IPupilProgress>('/pupil/progress', body, { optionalUserAuth: true });
  static updatePupilProgressAsTeacher = (pupilId: number, body: { lessonId: number, status: EPupilProgressStatus, result: string }): Promise<IPupilProgress> => Request.put<IPupilProgress>(`/pupil/${pupilId}/progress`, body);
  static updateManyPupilProgressAsTeacher = (pupilId: number, body: { lessonIds: number[], status: EPupilProgressStatus }): Promise<IPupilProgress> => Request.put<IPupilProgress>(`/pupil/${pupilId}/progress/bulk`, body);

  // Lesson
  static listLessons = (params: { category?: ELessonCategory, level?: ELessonLevel, book?: ELessonBook, published?: boolean }): Promise<Lesson[]> => Request.get<Lesson[]>(`/lesson${buildQueryString(params)}`);
  static createLesson = (lesson: Omit<Lesson, 'id' | 'createdAt' | 'updatedAt'>): Promise<Lesson> => Request.post<Lesson>('/lesson', lesson);
  static updateLesson = (id: number, lesson: Omit<Lesson, 'id' | 'createdAt' | 'updatedAt'>): Promise<Lesson> => Request.put<Lesson>(`/lesson/${id}`, lesson);
  static getLesson = (id: number): Promise<Lesson> => Request.get<Lesson>(`/lesson/${id}`);
  static deleteLesson = (id: number): Promise<void> => Request.delete<void>(`/lesson/${id}`);

  // Usage
  static listUsageRuns = (): Promise<IUsageRun[]> => Request.get<IUsageRun[]>('/usage');
  static getUsageRun = (runId: string): Promise<IUsage[]> => Request.get<IUsage[]>(`/usage/${runId}`);

  // Storage
  static uploadFile = (blob: Blob, filename: string): Promise<{ filename: string }> => Request.postFile<{ filename: string }>('/storage', filename, blob);
  static uploadRecording = (blob: Blob, filename: string, { speechContext, transcribe }: { transcribe?: boolean, speechContext?: string[] } = {}): Promise<RecordingItem> => {
    const params = transcribe ? buildQueryString({ transcribe }) : '';
    const fields = speechContext ? { speechContext } : undefined;
    return Request.postFile<RecordingItem>(`/storage/recording${params}`, filename, blob, fields);
  };

  static uploadImage = (blob: Blob, filename: string): Promise<Image> => Request.postFile<Image>('/storage/image', filename, blob);
  static uploadAvatar = (blob: Blob, filename: string): Promise<{ filename: string }> => Request.postFile<{ filename: string }>('/storage/avatar', filename, blob);

  // Instruction Labels
  static listInstructionLabels = (): Promise<TInstructionLabelMap> => Request.get<TInstructionLabelMap>('/instruction-label', { optionalUserAuth: true });
  static setInstructionLabel = (label: Omit<IInstructionLabel, 'id'>): Promise<IInstructionLabel> => Request.post<IInstructionLabel>('/instruction-label', label);
}
