import { SelectLayout } from '../common/layouts/select.layout';
import { PupilLayout } from '../common/layouts/pupil.layout';
import { EInstructionLabel, ELessonCategory, ELessonLevel, LESSON_VARIANTS } from 'shared';
import { LessonCategory } from '../common/utils/lesson-category.component';
import { Link } from 'react-router-dom';
import './select-lesson.scss';
import { ELessonRouterPath } from '../router-path';
import { usePupil } from '../common/pupil-provider/pupil.context';
import { useQuery } from 'react-query';
import { Api } from '../common/api/api';
import { Status } from '../common/utils/status.component';
import { useEffect, useState } from 'react';

export function SelectLesson(): JSX.Element {
  const [filteredLessonCategories, setFilteredLessonCategories] = useState<{ category: ELessonCategory, level: ELessonLevel }[]>();
  const { pupil } = usePupil();
  const { data: pupilGroup, error, isLoading } = useQuery(['pupil-group', pupil], () => {
    if (!pupil) {
      return Promise.resolve(undefined);
    }
    return Api.getPupilGroup(pupil.id);
  });
  const { data: lessonCategories, status } = useQuery(['lesson-categories', pupilGroup], () => Api.findAvailableCategoriesByLevel(pupilGroup!.level)
    , {
      enabled: !!pupilGroup
    });

  useEffect(() => {
    if (pupilGroup) {
      const newFilteredLessonCategories = LESSON_VARIANTS.filter(item => item.level === pupilGroup.level);
      setFilteredLessonCategories(newFilteredLessonCategories.filter((filteredLessonCategories) => lessonCategories?.includes(filteredLessonCategories.category)));
    }
  }, [pupilGroup, lessonCategories]);

  return <PupilLayout horizontalBackground={true} >
    <SelectLayout actionInstructionLabel={EInstructionLabel.KIES_EEN_LES} actionAlign="start">
      <Status loading={isLoading || status !== 'success'} error={error} />
      <ul className="select-lesson__lessons">
        {filteredLessonCategories?.map(({ category: lessonCategory }) => <li key={lessonCategory} className={`select-lesson__lesson select-lesson__lesson--${lessonCategory}`}>
          {lessonCategory === ELessonCategory.BOOKSHELF ?
            <div className="select-lesson__lesson-link select-lesson__lesson-link--disabled">
              <span className="select-lesson__lesson-title"><LessonCategory value={lessonCategory} upper={true} level={pupilGroup?.level} /></span>
            </div> :
            <Link to={ELessonRouterPath.LIST_LESSONS.fullPath(lessonCategory)} className="select-lesson__lesson-link">
              <span className="select-lesson__lesson-title"><LessonCategory value={lessonCategory} upper={true} level={pupilGroup?.level} /></span>
            </Link>
          }
        </li>)}
      </ul>
    </SelectLayout>
  </PupilLayout>;
}
