import './school-admin-list.scss';
import { AddSchoolAdmin } from './add-school-admin.component';
import { Api } from '../../common/api/api';
import { BoxDivider } from '../../common/utils/box.component';
import { Dispatch, SetStateAction, useState } from 'react';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { IUserWithEmail } from 'shared';
import { Modal } from 'react-bootstrap';
import { PasswordInput, Text, Popover, MantineProvider } from '@mantine/core';
import { Status } from '../../common/utils/status.component';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User } from '../../common/api/types';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/esm/Table';

const AWS_COGNITO_MIN_PASSWORD_LENGTH = 8;
const AWS_COGNITO_MAX_PASSWORD_LENGTH = 80;

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
  return (
    <Text className={`passwordRequirement passwordRequirement--${meets ? 'meets' : 'failed'}`}>
      {meets ? (
        <Icon>{EIcon.CHECK_CIRCLE}</Icon>
      ) : (
        <Icon>{EIcon.X_CIRCLE}</Icon>
      )}{' '}
      <span>{label}</span>
    </Text>
  );
}

function getPasswordRequirements() {
  const { t } = useTranslation();

  return [
    { re: /[0-9]/, label: t('school:password:require-number') },
    { re: /[a-z]/, label: t('school:password:require-lowercase-letter') },
    { re: /[A-Z]/, label: t('school:password:require-uppercase-letter') },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: t('school:password:require-special-character') },
  ];
}

function PasswordProgressBar({ passwordStrength }: { passwordStrength: number }) {
  const state = passwordStrength === 100 ? 'valid' : passwordStrength > 50 ? 'almost-valid' : 'invalid';

  return (
    <div className="passwordProgress">
      <div className={`passwordProgress-bar passwordProgress-bar--${state}`} style={{ width: `${passwordStrength}%` }}></div>
    </div>
  );
}

function getPasswordStrength(password: string) {
  let multiplier = password.length >= AWS_COGNITO_MIN_PASSWORD_LENGTH ? 0 : 1;

  getPasswordRequirements().forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (getPasswordRequirements().length + 1)) * multiplier, 10);
}

interface IChangePasswordModalProps {
  modalOpen: boolean;
  handleCloseModal: Dispatch<SetStateAction<void>>;
  user: User;
  onChangePasswordAdmin: (user: User, newPassword: string) => void;
}

function ChangePasswordModal({ modalOpen, handleCloseModal, user, onChangePasswordAdmin }: IChangePasswordModalProps): JSX.Element|null {
  const { t } = useTranslation();

  const [popoverOpened, setPopoverOpened] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const otherPasswordRequirements = getPasswordRequirements().map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(password)} />
  ));

  const passwordStrength = getPasswordStrength(password);

  const validatePassword = (password: string) => {
    // Password meets requirements
    if (password !== '' && passwordStrength === 100) {
      onChangePasswordAdmin(user, password);
      return;
    }
    // Password does not meet requirements
    setErrorMessage(t('school:password:does-not-meet-requirements').toString());
  };

  return (
    <Modal
      onHide={() => {
        handleCloseModal();
        setPassword('');
      }}
      size="xl"
      show={modalOpen}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('school:change-password-user')} {user.firstName} {user.lastName}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <MantineProvider>
          <Popover opened={popoverOpened} position="bottom" transitionProps={{ transition: 'pop' }} width="target" withinPortal={false}>
            <Popover.Target>
              <div
                onFocusCapture={() => {
                  setPopoverOpened(true);
                  setErrorMessage('');
                }}
                onBlurCapture={() => setPopoverOpened(false)}
              >
                <PasswordInput
                  error={errorMessage}
                  label={t('school:password:new')}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                  placeholder={t('school:password:new')}
                  value={password}
                  withAsterisk
                  maxLength={AWS_COGNITO_MAX_PASSWORD_LENGTH}
                />
              </div>
            </Popover.Target>
            <Popover.Dropdown>
              <PasswordProgressBar passwordStrength={passwordStrength} />
              <PasswordRequirement label={t('school:password:require-length')} meets={password.length >= AWS_COGNITO_MIN_PASSWORD_LENGTH} />
              {otherPasswordRequirements}
            </Popover.Dropdown>
          </Popover>
        </MantineProvider>
        <Button onClick={() => validatePassword(password)}>{t('school:password:change')}</Button>
      </Modal.Body>
    </Modal>
  );
}

interface IAdminTableProps {
  schoolId: number;
  admins?: IUserWithEmail[];
  onAddAdmin: (user: User) => void;
  onRemoveAdmin: (user: User) => void;
  onChangePasswordAdmin: (user: User) => void;
}

function AdminTable({ schoolId, admins, onAddAdmin, onRemoveAdmin, onChangePasswordAdmin }: IAdminTableProps): JSX.Element|null {
  const { t } = useTranslation();

  if (!admins) {
    return null;
  }

  return <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        { admins.map(item => <tr key={item.user.id}>
          <td>{item.user.firstName}</td>
          <td>{item.user.lastName}</td>
          <td>{item.email}</td>
          <td className="actionsRow">
            <Button onClick={() => onChangePasswordAdmin(item.user)}>{t('school:password:change')}</Button>
            <Button className="btn-warning" onClick={() => onRemoveAdmin(item.user)}>{t('school:user:remove')}</Button>
          </td>
        </tr>)}
      </tbody>
    </Table>
    <BoxDivider title="Admin toevoegen" />
    <AddSchoolAdmin schoolId={schoolId} onAddAdmin={onAddAdmin} />
  </>;
}

export function SchoolAdminList({ schoolId }: { schoolId: number }): JSX.Element {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: admins, error, isLoading } = useQuery(['schooladmins', schoolId], () => Api.listSchoolAdmins(schoolId));
  const [changePasswordModalUser, setChangePasswordModalUser] = useState<User>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const removeAdminMutation = useMutation((user: User) => Api.removeSchoolAdmin(schoolId, user.id), {
    onSuccess: () => queryClient.invalidateQueries('schooladmins'),
  });

  const handleAddAdmin = () => {
    queryClient.invalidateQueries('schooladmins')
      .catch(e => {
        console.error('Failed to invalidate schooladmins query', e);
      });
  };

  const handleRemoveAdmin = (user: User) => {
    removeAdminMutation.mutate(user);
  };

  const openPasswordChangeModal = (user: User) => {
    setChangePasswordModalUser(user);
    setModalOpen(true);
  };

  const handlePasswordChangeAdmin = (user: User, newPassword: string) => {
    setModalOpen(false);

    Api.changePasswordUser(user.id, newPassword)
      .then(() => {
        toast.info(t('school:password:changed').toString(), { autoClose: 5000 });
      })
      .catch((error) => {
        toast.error(`${t('school:password:change-failed')}! ${t('school:errors:technical-error')}`, { autoClose: false });
        console.error('Failed changing password of user', error);
      });
  };

  let changePasswordModal;
  if (changePasswordModalUser) {
    changePasswordModal = <ChangePasswordModal modalOpen={modalOpen} handleCloseModal={() => setModalOpen(false)} user={changePasswordModalUser} onChangePasswordAdmin={handlePasswordChangeAdmin} />;
  }

  return <>
    <Status loading={isLoading} error={error} />
    {changePasswordModal}
    <AdminTable schoolId={schoolId} admins={admins} onAddAdmin={handleAddAdmin} onRemoveAdmin={handleRemoveAdmin} onChangePasswordAdmin={openPasswordChangeModal} />
  </>;
}
