import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ILesson, EInstructionLabel, WordTowerExercise, WORD_TOWER_REPEAT_COUNT, Recording, WordPart, ELessonLevel, ELessonBook } from 'shared';
import { IExerciseProps } from '..';
import { playAudio } from '../../../common/utils/audio.helper';
import { useDeveloperByPassMode } from '../../../common/utils/developer-bypass';
import { EIcon } from '../../../common/utils/icon.component';
import { InstructionLabel } from '../../../common/utils/instruction-label.component';
import { RecordingPlayer } from '../../../common/utils/recording-player.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { Status } from '../../../common/utils/status.component';
import { getStorageUrl } from '../../../common/utils/storage.helper';
import { DecodingLayout } from './decoding-layout';
import { Lesson } from '../../../common/api/types';
import vlamYoung from './vlam-young.png';
import vlamOlder from './vlam-older.png';
import vlamStrong1 from './vlam_strong_1.png';
import vlamStrong2 from './vlam_strong_2.png';
import vlamStrong3 from './vlam_strong_3.png';
import vlamStrong4 from './vlam_strong_4.png';
import vlamStrong5 from './vlam_strong_5.png';
import vlamStrong6 from './vlam_strong_6.png';
import './show-word-tower.scss';
import { usePupil } from '../../../common/pupil-provider/pupil.context';
import { resolveRecordingItem } from '../../../common/utils/recording-resolver';

const VLAM_STRONG_IMAGES = [vlamStrong1, vlamStrong2, vlamStrong3, vlamStrong4, vlamStrong5, vlamStrong6];

function getRandomVlamStrong(lessonNumber: number, exerciseIndex: number): string {
  const offset = Number(String(Math.abs(Math.round(lessonNumber))).charAt(0)) || 0;
  const index = Math.abs(Math.round(exerciseIndex)) || 0;
  const vlamIndex = (offset + index) % VLAM_STRONG_IMAGES.length;
  return VLAM_STRONG_IMAGES[vlamIndex];
}

interface IWordTower {
  recording: Recording | undefined,
  letterBlocks: {
    content: string,
    width: number
  }[]
}

const getTowerRowWidth = (parts: WordPart[], partIndex: number, tower: IWordTower[], blockIndex: number) => {
  if (partIndex === 0) return 1;
  if (tower.length > 0) {
    const previousRow: IWordTower = tower[partIndex - 1];

    if (previousRow) {
      if (previousRow.letterBlocks.length > 1 && previousRow.letterBlocks[0].width > 1) return previousRow.letterBlocks.reduce((totalWidth, block) => totalWidth + block.width, 0);
      if (previousRow.letterBlocks.length > 1) return previousRow.letterBlocks.length;
      if (previousRow.letterBlocks[0].width && blockIndex === 0) return previousRow.letterBlocks[0].width;
    }
  }
  return 1;
};

const buildWordTower = (parts: WordPart[] | undefined): IWordTower[] | undefined => {
  if (!parts) return undefined;

  const tower: IWordTower[] = [];
  for (let partIndex = 0; partIndex < parts.length; partIndex++) {
    const part = parts[partIndex];

    tower.push({
      letterBlocks: part.letterBlocks.map((block, blockIndex) => ({ content: block, width: getTowerRowWidth(parts, partIndex, tower, blockIndex) })),
      recording: part.recording
    });
  }

  return tower;
};

export type TWordTowerRenderer = (parts: IWordTower[], partIndex: number) => JSX.Element;

const WordTowerRenderer: TWordTowerRenderer = (parts, partIndex): JSX.Element => {
  const [columnWidth, setColumnWidth] = useState<number>(0);

  useEffect(() => {
    const firstGroup = document.querySelector<HTMLElement>('.word-tower-display__group') as HTMLElement;
    let longest = 0;
    const longestPart = Array.from(firstGroup.children).reduce((a, b) => ((a as HTMLElement).offsetWidth > (b as HTMLElement).offsetWidth ? a : b)) as HTMLElement;
    if (longest < longestPart.offsetWidth) {
      longest = longestPart.offsetWidth;
    }
    setColumnWidth(longest);
  });

  return <>
    {parts.map((part, rowIndex) => <div key={rowIndex} style={{ gridTemplateColumns: `repeat(auto-fit, minmax(5.875rem, calc(${columnWidth}px + 64px)))` }} className={classNames('word-tower-display__row', { 'word-tower-display__row--active': rowIndex <= partIndex })}>
      {part.letterBlocks.map((block, groupIndex) => <div key={groupIndex} className="word-tower-display__group" style={{
        '--span': block.width,
        ...(block.width === 1 && block.content.length > 2) ? { justifyContent: 'center' } : {},
      } as React.CSSProperties}>
        <span className="word-tower-display__part">{block.content}</span>
      </div>
      )}
    </div>)}
  </>;
};

interface IWordTowerDisplay {
  exercise: WordTowerExercise;
  partIndex: number;
  onPlayingAudio: (isPlaying: boolean) => void;
  wordTowerRenderer: TWordTowerRenderer;
}

function WordTowerDisplay({ exercise, partIndex, onPlayingAudio, wordTowerRenderer }: IWordTowerDisplay): JSX.Element {
  const { language } = usePupil();
  const parts = buildWordTower(exercise.data?.wordParts);
  const [playbackError, setPlaybackError] = useState<string | null>(null);

  useEffect(() => {
    setPlaybackError(null);

    if (partIndex < 0) {
      return;
    }

    onPlayingAudio(true);

    // Let the text be displayed, the pupil process it, and then play the audio.
    const timeout = setTimeout(() => {
      const part = parts && parts[partIndex];
      if (!part) {
        setPlaybackError('Data voor rij kon niet worden geladen');
        onPlayingAudio(false);
        return;
      }

      const recordingItem = resolveRecordingItem(part.recording, language);

      if (!recordingItem) {
        // setPlaybackError('Geluidsfragment voor rij bestaat niet');
        onPlayingAudio(false);
        return;
      }

      playAudio(recordingItem)
        .catch(err => {
          // setPlaybackError('Geluidsfragment voor rij kan niet worden geladen');
          console.error('Could not play audio', recordingItem, err);
        })
        .finally(() => onPlayingAudio(false));
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [partIndex, exercise.id, language]);

  if (!parts || parts.length === 0) {
    return <Status error="Geen data beschikbaar om woord toren op te bouwen" />;
  }

  return <>
    <div className="word-tower-display">
      {wordTowerRenderer(parts, partIndex)}
    </div>
    <Status error={playbackError} />
  </>;
}

interface IFinalImageDisplay {
  exercise: WordTowerExercise;
  lesson: Lesson;
  exerciseIndex: number;
  onPlayingAudio: (isPlaying: boolean) => void;
}

function WordTowerSummary({ lesson, exerciseIndex, exercise, onPlayingAudio }: IFinalImageDisplay): JSX.Element {
  const { level, book, number } = lesson;
  const { language } = usePupil();
  const [playbackError, setPlaybackError] = useState<string | null>(null);
  const exerciseData = exercise.data;
  const displayWord = exercise.data?.pre || exercise.data?.word;
  const recording = exercise.data?.recording || exercise.data?.fullRecording; // recording = pre + word , fullRecording = word. We should fix that

  let image = exerciseData?.image && getStorageUrl(exerciseData.image.smallFilename);
  if (!image && book && [ELessonBook.EXTRA_ONE].includes(book)) {
    image = getRandomVlamStrong(number, exerciseIndex);
  } if (!image && [ELessonLevel.LEVEL_3, ELessonLevel.LEVEL_4].includes(level)) {
    image = vlamYoung;
  } else if (!image) {
    image = vlamOlder;
  }

  const playAudioClip = (recording: Recording) => {
    const recordingItem = resolveRecordingItem(recording, language);
    if (!recordingItem) {
      setPlaybackError('Geluidsfragment voor resultaat kan niet worden geladen');
      console.error('Could not find audio', { recording, language });
      return;
    }

    onPlayingAudio(true);
    playAudio(recordingItem)
      .catch(err => {
        setPlaybackError('Geluidsfragment voor resultaat kan niet worden geladen');
        console.error('Could not play audio', recording, err);
      })
      .finally(() => onPlayingAudio(false));
  };

  useEffect(() => {
    setPlaybackError(null);
    onPlayingAudio(true);

    if (!recording) {
      setPlaybackError('Geluidsfragment voor resultaat bestaat niet');
      onPlayingAudio(false);
      return;
    }

    // Let the text and image be displayed, the pupil processes it, and then play the audio.
    const timeout = setTimeout(() => {
      playAudioClip(recording);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [exercise]);

  return <div className="word-tower-summary">
    <img className="word-tower-summary__image" src={image} alt="" />
    {displayWord && <div className="word-tower-summary__word">
      <RecordingPlayer className="word-tower-summary__word-value" recording={recording} language={language}>{displayWord}</RecordingPlayer>
    </div>}
    <Status error={playbackError} />
  </div>;
}

interface IShowWordTowerProps extends IExerciseProps<WordTowerExercise> {
  progress: number;
  lesson: ILesson;
  exerciseIndex: number;
}

export function GenericShowWordTower({ wordTowerRenderer, instructionLabel, exercise, exerciseIndex, progress, lesson, onClose, onFinishExercise, onStepForward }: IShowWordTowerProps & { wordTowerRenderer: TWordTowerRenderer, instructionLabel: EInstructionLabel }): JSX.Element {
  const [partIndex, setPartIndex] = useState(0);
  const [showSummary, setShowSummary] = useState(false);
  const [currentIteration, setCurrentIteration] = useState(1);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const developerBypassMode = useDeveloperByPassMode();
  const atLastWordPart = partIndex >= (exercise.data?.wordParts?.length || 0) - 1;

  useEffect(() => {
    setPartIndex(0);
    setShowSummary(false);
    setCurrentIteration(1);
  }, [exercise]);

  const handleAudioIsPlaying = (isPlaying: boolean): void => {
    if (developerBypassMode) {
      setDisableNextButton(false);
      return;
    }
    setDisableNextButton(isPlaying);
  };


  useEffect(() => {
    if (!disableNextButton) {
      return;
    }

    /**
     * This is a hotfix/fallback for an issue were the continue button
     * stays disabled. This might be caused by some audio issues, but
     * we're not sure. Therefore we add this fallback so that the pupil
     * doesn't get stuck, but we need to find the root cause.
     */
    const fallbackEnableButtonTimeout = setTimeout(() => {
      setDisableNextButton(false);
      console.warn('Enabled continue button because disable delay was exceeded');
    }, 2500); // Wait 2.5s so we don't allow clicks before the audio has finished.

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(fallbackEnableButtonTimeout);
  }, [disableNextButton]);

  const handleGoToNext = () => {
    if (disableNextButton) {
      return;
    }

    if (showSummary && currentIteration < WORD_TOWER_REPEAT_COUNT) {
      setPartIndex(0);
      setShowSummary(false);
      setCurrentIteration(currentIteration + 1);
      return;
    }

    if (showSummary && currentIteration >= WORD_TOWER_REPEAT_COUNT) {
      onFinishExercise();
      return;
    }

    if (atLastWordPart) {
      setShowSummary(true);
      onStepForward();
      return;
    }

    setPartIndex(partIndex + 1);
    onStepForward();
  };

  return <DecodingLayout lesson={lesson} progress={progress} onClose={onClose}>
    <div className="show-word-tower">
      <div className="show-word-tower__instruction"><InstructionLabel instructionKey={instructionLabel} /></div>
      <WordTowerDisplay exercise={exercise} partIndex={partIndex} onPlayingAudio={handleAudioIsPlaying} wordTowerRenderer={wordTowerRenderer} />
      {showSummary && <WordTowerSummary lesson={lesson} exerciseIndex={exerciseIndex} exercise={exercise} onPlayingAudio={handleAudioIsPlaying} />}
      <RoundButton className="show-word-tower__next-button" icon={EIcon.CHEVRON_RIGHT} variant="success" onClick={handleGoToNext} disabled={disableNextButton}>Ga verder</RoundButton>
    </div>
  </DecodingLayout>;
}

export function ShowWordTower(props: IShowWordTowerProps): JSX.Element {
  return <GenericShowWordTower wordTowerRenderer={WordTowerRenderer} instructionLabel={EInstructionLabel.HERHAAL_DEZE_LETTER} {...props} />;
}
