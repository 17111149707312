import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TDifficultWord } from 'shared';
import { Image } from '../../../../../common/api/types';
import { DangerButton } from '../../../../../common/utils/danger-button.component';
import { EIcon } from '../../../../../common/utils/icon.component';
import { ImageInput } from '../../../../../common/utils/image-input.component';
import { useState } from 'react';

type TDifficultWordEditorProps = {
  text?: string | undefined;
  words?: TDifficultWord[] | undefined;
  onWordsChange: (value: TDifficultWord[], errors: string[]) => void;
}

export const ReadingComprehensionDifficultWordEditor = ({
  text,
  words,
  onWordsChange,
}: TDifficultWordEditorProps): JSX.Element => {
  const { t } = useTranslation();
  const [wordsErrors, setWordsErrors] = useState<string[]>([]);

  const difficultWordAdded = (word: string) => {
    const regex = new RegExp(`(^|[\\s;,."!?])${word}([\\s;,."!?]|$)`, 'i');

    return regex.test(text || '');
  };

  const handleDeleteDifficultWord = (index: number) => {
    if (!words) {
      return;
    }
    const copy = [...words];
    copy.splice(index, 1);
    onWordsChange(copy, wordsErrors);
  };

  const handleAddDifficultWord = () => {
    onWordsChange([...words ?? [], { word: '' }], wordsErrors);
  };

  const handleWordChange = (idx: number, newWord: string) => {
    setWordsErrors(errors => {
      errors[idx] = '';
      return errors;
    });

    const wordsCopy = Array.from(words || []);

    wordsCopy.forEach(({ word }) => {
      if (word === newWord) {
        setWordsErrors(errors => {
          errors[idx] = t('reading-comprehension:admin.difficult-words.already-exists', { word: newWord });
          return errors;
        });
      }
    });

    wordsCopy[idx].word = newWord;
    onWordsChange(wordsCopy, wordsErrors);
  };

  const handleImageChange = (idx: number, image: Image | null) => {
    const copy = Array.from(words || []);
    if (!image) {
      delete copy[idx].image;
    } else {
      copy[idx].image = image;
    }
    onWordsChange(copy, wordsErrors);
  };

  return (
    <>
      {words && words.map(({ word, image }, idx) => (
        <Row key={idx}>
          <Form.Group as={Col} md={4}>
            <Form.Label>
              {t('reading-comprehension:admin.difficult-words.label')}
            </Form.Label>
            <Form.Control value={word} onChange={(e) => handleWordChange(idx, e.target.value)} name={`difficult-word-${idx}}`}
              type="text"
              isValid={(text !== undefined) && difficultWordAdded(word)}
              isInvalid={!!wordsErrors[idx]}
            />
            <Form.Control.Feedback type="invalid">{wordsErrors[idx]}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={5}>
            <Form.Label>
              {t('reading-comprehension:admin.difficult-words.image')}
            </Form.Label>
            <ImageInput value={image} onChange={(e) => handleImageChange(idx, e)}/>
          </Form.Group>
          <Col md={3} className="reading-comprehension__delete-word-button-wrapper">
            <DangerButton
              className="reading-comprehension__delete-word-button"
              icon={EIcon.MINUS_CIRCLE}
              onClick={() => handleDeleteDifficultWord(idx)}
              modalText={t('reading-comprehension:admin.difficult-words.delete.text')}
              modalCancelLabel={t('common:keep')}
              modalConfirmLabel={t('common:delete')}
            >{t('reading-comprehension:admin.difficult-words.delete.label')}</DangerButton>
          </Col>
        </Row>
      ))}
      <Row>
        <Col>
          <button
            className="reading-comprehension__add-word-button"
            disabled={!!words?.find((word) => word.word === '')}
            onClick={handleAddDifficultWord}>+ {t('reading-comprehension:admin.difficult-words.label')}</button>
        </Col>
      </Row>
    </>
  );
};
